<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data.imageType === 'extra-wide'">
    <Image
      v-if="data?.image?.url"
      :url="data?.image?.url"
      :alt="data.image.alt"
      class="aspect-[376/250] xl:aspect-[1920/700] 2xl:aspect-[1920/700]"
    />
    <div
      v-if="data.image?.byline && data?.byline"
      class="px-2 py-2 text-right font-sans text-xs text-black lg:px-3 lg:py-3 lg:text-sm"
    >
      {{ data.image?.byline }}
    </div>
  </div>

  <Container v-else class="!px-0 lg:px-4">
    <Image
      v-if="data?.image?.url"
      :url="data?.image?.url"
      :alt="data.image.alt"
      class="aspect-[376/250] lg:rounded-2xl xl:aspect-[1260/500] 2xl:aspect-[1326/550]"
    />
    <div
      v-if="data.image?.byline && data?.byline"
      class="px-2 py-2 text-right font-sans text-xs text-black lg:px-3 lg:py-3 lg:text-sm"
    >
      {{ data.image?.byline }}
    </div>
  </Container>

  <Container class="mt-[60px] lg:mt-[50px]">
    <div class="mx-auto flex max-w-[650px] flex-col">
      <SubTitle v-if="data?.date" class="uppercase text-blue">
        {{ dayjs(data?.date).format('DD MMMM YYYY') }}
      </SubTitle>

      <SubTitle v-if="data?.subtitle" class="uppercase text-blue">{{ data?.subtitle }}</SubTitle>
      <Title>{{ data?.title }}</Title>
      <div
        v-if="data?.preamble"
        class="mt-4 font-sans text-lg font-bold text-blue lg:mt-6 lg:text-xl"
        v-html="data?.preamble"
      ></div>
      <div
        v-if="data?.body"
        class="prose mt-4 font-sans text-base text-black lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-lg [&_p]:mb-4"
        v-html="data?.body"
      ></div>
    </div>
  </Container>
  <slot></slot>

  <div class="flex flex-col items-center gap-8 bg-blue-lighter py-6 lg:py-8">
    <div class="flex items-center">
      <Image
        v-if="data?.author?.avatar?.url"
        :url="data?.author?.avatar?.url"
        :alt="data?.author?.title"
        class="aspect-square !w-[80px] rounded-full lg:!w-[100px]"
      />
      <div class="ml-7 flex flex-col lg:ml-9">
        <h3 v-if="data?.author?.title" class="font-sans text-base text-black lg:text-xl">
          {{ data?.author?.title }}
        </h3>
        <span v-if="data?.author?.position" class="mt-2 font-sans text-xs text-black lg:text-sm">
          {{ data?.author?.position }}
        </span>
      </div>
    </div>

    <div
      v-if="data?.tags && data?.tags.length > 0"
      class="flex flex-wrap items-center justify-center"
    >
      <BadgeCategory
        v-for="(tag, index) in data?.tags"
        :key="index"
        :class="[index === 0 && 'bg-blue-dark']"
        class="mx-2 mb-4 lg:mb-0"
        :path="tag.path"
      >
        {{ tag.title }}
      </BadgeCategory>
    </div>
    <div class="flex items-center">
      <span class="mr-4 font-serif text-base uppercase text-black">{{ $t('share') }}:</span>
      <ClientOnly fallback-tag="span">
        <ShareSocial :data="data" />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'

import { Title, Container, Image, BadgeCategory, SubTitle } from 'refresh-ui'

import ShareSocial from '@/components/molecules/ShareSocial.vue'
import 'dayjs/locale/sv'
import 'dayjs/locale/uk'
import 'dayjs/locale/en'
import { useI18n } from '#imports'

const { locale } = useI18n()

// dayjs locale
dayjs.locale(locale.value)

defineProps({
  data: Object,
})
</script>
